// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/codebuild/output/src418419041/src/oblakoder-website/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aws-for-developers-js": () => import("/codebuild/output/src418419041/src/oblakoder-website/src/pages/aws-for-developers.js" /* webpackChunkName: "component---src-pages-aws-for-developers-js" */),
  "component---src-pages-aws-well-architected-js": () => import("/codebuild/output/src418419041/src/oblakoder-website/src/pages/aws-well-architected.js" /* webpackChunkName: "component---src-pages-aws-well-architected-js" */),
  "component---src-pages-contact-js": () => import("/codebuild/output/src418419041/src/oblakoder-website/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("/codebuild/output/src418419041/src/oblakoder-website/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-migrate-to-aws-js": () => import("/codebuild/output/src418419041/src/oblakoder-website/src/pages/migrate-to-aws.js" /* webpackChunkName: "component---src-pages-migrate-to-aws-js" */),
  "component---src-pages-security-in-the-cloud-js": () => import("/codebuild/output/src418419041/src/oblakoder-website/src/pages/security-in-the-cloud.js" /* webpackChunkName: "component---src-pages-security-in-the-cloud-js" */),
  "component---src-pages-serverless-aws-js": () => import("/codebuild/output/src418419041/src/oblakoder-website/src/pages/serverless-aws.js" /* webpackChunkName: "component---src-pages-serverless-aws-js" */)
}

