module.exports = [{
      plugin: require('/codebuild/output/src418419041/src/oblakoder-website/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-149914253-1"},
    },{
      plugin: require('/codebuild/output/src418419041/src/oblakoder-website/node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/codebuild/output/src418419041/src/oblakoder-website/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
